import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";

import { formatCurrency } from "../../../../utils/tableUtils.js";
import { brandSort, conditionSort, formatMonths, generateGradientColors } from "../../../../utils/chartUtils.js";
import { useTheme } from "../../../../utils/ThemeManager.jsx";

import "./Category.css";

// data: project.keyword_universe
const Category = ({ data, category_type }) => {
  const { theme } = useTheme();
  const [options, setOptions] = useState({
    pie: {},
    bar: {},
  });

  const category_color_grades = {
    Branded: 6,
    Condition: 7
  }

  useEffect(() => {
    if (data == null || Object.keys(data).length === 0) return;

    const colors = theme === 'dark' 
      ? generateGradientColors('#FCFCFC', '#020021', category_color_grades[category_type])
      : generateGradientColors('#C4D6EB', '#0561B7', category_color_grades[category_type]);

    setOptions({
      pie: pieOptions(data, category_type, colors),
      bar: barOptions(data, category_type, colors, theme),
    });
  }, [theme]);

  if (data == null || !Object.keys(data).length) {
    return <p className="min-h-[450px] px-4 py-8 font-bold grid place-content-center">Estimated spend by category data is not available.</p>
  };

  return ( 
    <div className="grid grid-cols-6">
      <div className="col-span-2">
        <ChartDashboard config={options.pie} id={`spend-category-${category_type}-pie-container`} />
      </div>

      <div className="col-span-4">
        <ChartDashboard config={options.bar} id={`spend-category-${category_type}-bar-container`} />
      </div>  
    </div>
  );
};

export default Category;

const pieOptions = (data, category_type, colors) => {
  // remove the total and subtotal categories from the data
  const categories = Object.entries(data)
  .filter(([key, value]) => value.category_type === category_type)
  .sort((a, b) => {
    if (category_type === 'Branded') {
      return brandSort[a[0]] - brandSort[b[0]];
    } else {
      return conditionSort[a[0]] - conditionSort[b[0]];
    }
  })
  .map(([key]) => key);

  
  // pie chart
  const totalCost = categories.reduce((sum, category) => {
    return sum + (data[category]?.estimated_total_cost || 0);
  }, 0);

  const universePie = categories.map(category => {
    const cost = data[category]?.estimated_total_cost || 0;
    
    return {
      name: category.replace('Uncategorized - ', ''), 
      y: (cost / totalCost) * 100, 
      visible: cost > 1
    };
  });

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `${category_type}-estimated-spend-category-pie-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `${category_type}-estimated-spend-category-pie-cell`,
        chartOptions: {
          colors: colors,
          chart: {
            styledMode: false,
            type: "pie",
            height: 450,
          },
          title: {
            text: `${category_type} Estimated Total Cost by Category`,
          },
          tooltip: {
            enabled: true,
            stickOnContact: true,
            formatter: function () {
              const colorDot = `<span style="color:${this.point.color};">●</span>`;
              return `${colorDot} <b>${this.point.name}</b>: ${Highcharts.numberFormat(this.point.percentage, 1)}%`;
            },
          },
          subtitle: {
            text: "",
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: [
                {
                  distance: -40,
                  enabled: true,
                  formatter: function () {
                    if (this.point.visible && this.percentage > 0.5) {
                      return `${Highcharts.numberFormat(this.point.percentage, 1)}%`;
                    }
                  },
                  style: {
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: 'contrast',
                    textOutline: "1px solid contrast",
                  },
                },
              ],
            },
          },
          series: [
            {
              name: "Percentage",
              type: "pie",
              data: universePie,
            },
          ],
        },
      },
    ],
  };
};

const barOptions = (data, category_type, colors, theme) => {
  // remove the total and subtotal categories from the data
  const categories = Object.entries(data)
  .filter(([key, value]) => value.category_type === category_type)
  .sort((a, b) => {
    if (category_type === 'Branded') {
      return brandSort[a[0]] - brandSort[b[0]];
    } else {
      return conditionSort[a[0]] - conditionSort[b[0]];
    }
  })
  .map(([key]) => key);

  // stacked bar chart
  const universeBar = Array.from({ length: 12 }, (_, monthIndex) => {
    return [ ...categories.map(category => data?.[category]?.monthly_cost_trend?.[monthIndex] || 0)];
  });

    return {
    dataPool: {
      connectors: [
        {
        id: 'estimated-spend-category-bar',
        type: 'JSON',
        options: {
          firstRowAsNames: false,
          columnNames: categories,
          data: universeBar,
        }
      },
    ]
    },
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `${category_type}-estimated-spend-category-bar-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `${category_type}-estimated-spend-category-bar-cell`,
        connector: {
          id: 'estimated-spend-category-bar',
          columnAssignment: [
            {
              seriesId: 'Branded',
              data: categories,
            },
        ]
        },
        chartOptions: {
          colors: colors,
          chart: {
            type: "column",
            styledMode: false,
            height: 450,
          },
          title: {
            text: null,
          },
          xAxis: {
            categories: formatMonths(data[Object.keys(data)[0]].search_volume_months),
          },
          yAxis: {
            title: {
              text: null
            },
            min: 0,
            labels: {
              formatter: function () {
                // if millions, divide by 1,000,000 and add 'M'
                // if thousands, divide by 1,000 and add 'K'
                if (this.value >= 1000000) return `${this.value / 1000000}M`;

                return this.value >= 1000 ? `${this.value / 1000}K` : this.value;

              },
            },
            stackLabels: {
              enabled: true,
              textOutline: "none",
              formatter: function () {
                // dollar sign and 2 decimal places
                return formatCurrency(this.total, 0);
              },
              style: {
                color: theme === 'dark' ? '#3AE500' : '#020021',
                textOutline: "none",
              }
            },
          },
          tooltip: {
            formatter: function () {
                const colorIndex = this.series.colorIndex || this.point.colorIndex || 0;
                const color = colors[colorIndex];
                const coloredDot = `<span style="color:${color};">● </span>`;
                const formattedPercentage = Highcharts.numberFormat(this.point.percentage, 1);

                return `${coloredDot}${this.series.name}: ${formatCurrency(this.point.y, 0)} (${formattedPercentage}%)`;
            }
        },
          plotOptions: {
            series: {
              stacking: "normal",
            },
          },
        },
      },
    ],
  };
};