import { Link, useParams, useLocation } from "react-router-dom";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

import { formatCurrency, formatLargeNumber } from "../../../utils/tableUtils";

import Loading from "../../../components/Loading/Loading";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import Progress from "./Progress/Progress";
import SearchVolMonth from "./Visualizations/SearchVolMonth";

import useKeyword from "./hooks/useKeyword";

import '../Admin.css';
import './KeywordProfile.css'

function KeywordProfile() {
  const { keywordId } = useParams();
  const { keyword, searchUrl, loading, error } = useKeyword(keywordId);
  const location = useLocation();
  const history = location.state?.history || [];

  if (loading) return <Loading />

  if (error) {
    return (
      <div className="page-base space-y-4 md:space-y-8">
        { history && history.length > 0 && <nav aria-label="breadcrumbs">
          <ol className="flex gap-2 text-xs md:text-sm items-center">
            {history.map((breadcrumb, index) => (
              <li key={index} className="flex items-center">
                <Link to={breadcrumb.path} state={{ history: history.slice(0, index) }}>{breadcrumb.name}</Link>

                  <FontAwesomeIcon
                    className="ml-2 text-black dark:text-white"
                    icon={faChevronRight}
                    aria-hidden="true"
                  />

              </li>
            ))}
            <li className="font-bold text-watermelon-500">
              Keyword Profile
            </li>
          </ol>
        </nav> }
        
        <ErrorDisplay error={error} />
      </div>
    );
  }

  return <>
    <div className="admin-page keyword-profile page-base">
    { history && history.length > 0 && <nav aria-label="breadcrumbs">
        <ol className="flex gap-2 text-xs md:text-sm items-center">
          {history.map((breadcrumb, index) => (
            <li key={index} className="flex items-center">
              <Link 
                to={breadcrumb.path}
                state={{ history: history.slice(0, index) }}
              >{breadcrumb.name}</Link>

                <FontAwesomeIcon
                  className="ml-2 text-black dark:text-white"
                  icon={faChevronRight}
                  aria-hidden="true"
                />

            </li>
          ))}
          <li className="font-bold text-watermelon-500">
            Keyword Profile
          </li>
        </ol>
      </nav> }


      <section className="page-section flex flex-col">
        <h1 className="section-header">
          <a 
            className="capitalize flex gap-2 items-center" 
            href={searchUrl} 
            target="_blank"
            rel="noreferrer noopener"          
          >
            <span>{ keyword.value }</span>
            <span className="sr-only">Opens in an new tab</span>
            <span className="text-white text-sm">
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </span>
          </a>
        </h1>

        <div className="p-4 space-y-4">
          <div className="flex flex-col md:flex-row gap-4">
            <Progress 
              id="keyword_difficulty"
              label="Keyword Difficulty" 
              value={keyword.latest_monthly_data.keyword_difficulty} 
              max="100" 
            />

            <Progress 
              id="ad_density"
              label="Ad Density" 
              value={keyword.latest_monthly_data.google_ads_competitive_density} 
              max="1" 
            />
          </div>

          <div className="info">
            <div className="page-section info-item">
              <h2 className="section-header">AMSV</h2>
              <p>{formatLargeNumber(keyword.latest_monthly_data.search_volume_avg)}</p>
            </div>

            <div className="page-section info-item">
              <h2 className="section-header">Intent</h2>
              <p>{ keyword.latest_monthly_data.keyword_intent || 'N/A' }</p>
            </div>

            <div className="page-section info-item">
              <h2 className="section-header">CPC</h2>
              <p>{formatCurrency(keyword.latest_monthly_data.google_ads_cpc_avg)}</p>
            </div>

            <div className="page-section info-item">
              <h2 className="section-header">Type</h2>
              <p>{ keyword.keyword_type }</p>
            </div>

            <div className="page-section info-item flex flex-col">
              <h2 className="section-header">Category</h2>
              <ul className={`flex-1 ${ keyword.categories && keyword.categories.length > 1 ? 'list-disc' : ''} content-center lg:text-xl`}>
                { keyword.categories && keyword.categories.length > 0 
                  ? keyword.categories.map((category, index) => {
                    return <li className="md:text-center" key={index}>{ category }</li>
                  }
                )
                : <li>N/A</li>
                }
              </ul>
            </div>

            <div className="page-section info-item opacity-40">
              <h2 className="section-header">Subcategory</h2>
              <p>--</p>
            </div>
          </div>
        </div>

        <hr className="border-[#0561B7] border-opacity-50 border-dotted mx-4" />

        { keyword.latest_monthly_data.search_volume_monthly &&
          keyword.latest_monthly_data.search_volume_monthly.every(num => num === 0)
          ? <p className="p-8 text-center bg-opacity-50 border border-white border-opacity-50 rounded">No monthly search volume data available.</p>
          : <SearchVolMonth data={keyword.latest_monthly_data} />
        }

      </section>

      <section className="page-section">
          <h2 className="section-header">Keyword Intent</h2>

          <div className="p-4 space-y-2">
            <h3 className="font-bold">Why would someone perform this search?</h3>
            <p>Why would someone be searching google for the keyword, "{ keyword.value }"? Limit response to bullets and 200 words.</p>
          </div>
      </section>

      <section className="page-section">
          <h2 className="section-header">SERP Features</h2>
          <div className="p-4 space-y-4">
            <h3 className="font-bold">What SERP features exist?</h3>
            
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              { keyword.latest_monthly_data.media && 
              keyword.latest_monthly_data.media.length > 0 
                ? keyword.latest_monthly_data.media.map((media, index) => {
                  return <div className="border border-white p-4 text-center rounded" key={index}>
                    <h3>{ media }</h3>
                  </div>

              })
              : <p className="p-4 text-center col-span-full">No SERP features found.</p>
            }
            </div>
          </div>

        </section>
    </div>
  </>
}

export default KeywordProfile;