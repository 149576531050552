import { Suspense, useState, useEffect, useRef } from 'react'
import { TextureLoader, SRGBColorSpace } from 'three';
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber'
import { useGLTF, Float, MeshTransmissionMaterial } from '@react-three/drei'
import { useControls } from 'leva'

import bgDark from '../assets/img/bg-dark-2.png'
import bgLight from '../assets/img/bg-light-2.png'

import sunrise from '../assets/models/sunrise.glb'

import { useTheme } from '../utils/ThemeManager';

export default function Sunrise(props) {
  const { theme } = useTheme()
  const { nodes } = useGLTF(sunrise)
  const sunriseRef = useRef()

  const { scene } = useThree()

  const darkTexture = useLoader(TextureLoader, bgDark)
  const lightTexture = useLoader(TextureLoader, bgLight)
  // darkTexture.encoding = lightTexture.encoding = SRGBColorSpace;
  darkTexture.colorSpace = lightTexture.colorSpace = SRGBColorSpace;


  // const materialProps = useControls({
  //   backside: false,
  //   samples: { value: 16, min: 1, max: 256, step: 1 },
  //   resolution: { value: 2048, min: 64, max: 2048, step: 64 },
  //   transmission: { value: 1.0, min: 0, max: 1 },
  //   roughness: { value: 0.75, min: 0, max: 1, step: 0.01 },
  //   clearcoat: { value: 0.1, min: 0, max: 1, step: 0.01 },
  //   clearcoatRoughness: { value: 0.1, min: 0, max: 1, step: 0.01 },
  //   thickness: { value: 0.71, min: 0, max: 200, step: 0.01 },
  //   backsideThickness: { value: 0, min: 0, max: 200, step: 0.01 },
  //   ior: { value: 1.0, min: 1, max: 5, step: 0.01 },
  //   chromaticAberration: { value: 0.03, min: 0, max: 1 },
  //   anisotropy: { value: 0, min: 0, max: 10, step: 0.01 },
  //   distortion: { value: 0.16, min: 0, max: 1, step: 0.01 },
  //   distortionScale: { value: 0.01, min: 0.01, max: 1, step: 0.01 },
  //   temporalDistortion: { value: 0, min: 0, max: 1, step: 0.01 },
  //   attenuationDistance: { value: 0.5, min: 0, max: 10, step: 0.01 },
  //   attenuationColor: '#ffffff',
  //   color: '#ffffff',
  // })
  const materialProps = {
    backside: false,
    samples: 16,
    resolution: 2048,
    transmission: theme === 'dark' ? 0.98 : 0.5,
    roughness: 0.75,
    clearcoat: 0.1,
    clearcoatRoughness: 0.1,
    thickness: 0.25,
    backsideThickness: 0,
    ior: 1.33,
    chromaticAberration: 0.03,
    anisotropy: 0,
    distortion: 0.16,
    distortionScale: 0.01,
    temporalDistortion: 0,
    attenuationDistance: 0.5,
    attenuationColor: '#ffffff',
    color: '#ffffff',
  }

  useEffect(() => {
    scene.background = theme === 'dark' ? darkTexture : lightTexture
  }, [theme])

  useFrame((state, delta) => {
    if (sunriseRef.current) {
      sunriseRef.current.rotation.y += delta * 0.25
    }
  })

  return <Float floatIntensity={0.025} rotationIntensity={0} speed={1}>
    <mesh ref={sunriseRef} renderOrder={100} geometry={nodes.SUN.geometry} {...props}>
      <MeshTransmissionMaterial {...materialProps} color={ theme === 'dark' ? '#ffffff' : '#ccc'} toneMapped={false} />
    </mesh>
  </Float>
}
