// react
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

// hooks
import useCompanyPolygon from "../../hooks/useCompanyPolygon";

// axios
import { $axios } from "../../services/axiosInstance";

// components
import DynamicCrumbs from "../../components/Breadcrumbs/DynamicCrumbs";
import Loading from "../../components/Loading/Loading";
import CompanyChart from "./CompanyChart";
import NewsFeed from "./NewsFeed";
import ProjectList from "../../components/ProjectList/ProjectList";
// context
import { useAppContext } from "../../context/AppContext";

// styles
import "./Company.css";

const Company = () => {
  const { updatingProjects, updatedProjects, projectStatusError } =
    useAppContext();

  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const uuid = useParams().companyId;
  const [polygonData, setPolygonData] = useCompanyPolygon(company?.ticker);
  const [selectedDifficulties, setSelectedDifficulties] = useState({
    lowest: true,
    low: true,
    moderate: true,
    high: true,
    highest: true,
  });

  // Function to handle toggle changes
  const handleToggle = (difficulty) => {
    setSelectedDifficulties((prev) => ({
      ...prev,
      [difficulty]: !prev[difficulty],
    }));
  };

  useEffect(() => {
    setLoading(true);

    $axios
      .get(`/company/company/${uuid}/`)
      .then((response) => {
        setCompany(response.data);

        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setError("Company not found");
        } else {
          setError("Error fetching company");
        }

        setLoading(false);
      });
  }, [uuid]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="company-page page-base">
        <section className="info">
          <div className="page-header">
            <h1>{error}</h1>
          </div>

          <p className="py-40 text-center">
            Try going back to the{" "}
            <Link
              className="bold text-watermelon-500 hover:underline"
              to="/companies"
            >
              Companies
            </Link>{" "}
            page.
          </p>
        </section>
      </div>
    );
  }

  return (
    <div className="company-page page-base">
      <DynamicCrumbs current={company.name} />
      <section className="page-section">
          <h1 className="section-header">{company.name}</h1>


        <div className="p-4">
          { loading 
            ? null 
            : polygonData && polygonData.description 
            ? <p>{polygonData.description}</p>
            : <p>No description available</p>
          }
        </div>

        <hr className="border-[#0561B7] border-opacity-50 border-dotted mx-4" />
        
        <CompanyChart company={company} marketCap={polygonData?.market_cap} />
      </section>

      <section className="page-section">
        {/* <div className="section-header flex justify-between">
          <h2>Projects</h2>

          <ProjectFilter
            selectedDifficulties={selectedDifficulties}
            handleToggle={handleToggle}
          />
        </div> */}

          <ProjectList
            company={company}
            projects={company.brand_project}
            selectedDifficulties={selectedDifficulties}
            projectStatus={updatingProjects.filter(
              (s) => s.parent_company === company.uuid
            )}
            withHeader={true}
          />

      </section>

      <section className="page-section">
        <h2 className="section-header">Recent News</h2>

        <NewsFeed ticker={company.ticker} />
      </section>
    </div>
  );
};

export default Company;
