import Summary from '../../../../components/Summary/Summary';
import spinner from '../../../../assets/img/spinner.svg';

import './MedicationOverview.css';

export const formatDate = (dateString) => {
  if (dateString == null || dateString === "") return "N/A";

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

function MedicationOverview({ project, brand, loading }) {
  if(loading) {
    return <div className="medication-overview-container">
      <div className="overview-bar loading">
        <img src={spinner} alt="Loading..." className="h-8 w-8" />
      </div>

      <div className="info-container loading">
        <img src={spinner} alt="Loading..." className="h-8 w-8" />
      </div>
    </div>
  }

  return (
    <div className="medication-overview-container">
      <div className="overview-bar">
        <div className="info-container">
          <div className="overview-item">
            <h3>Approved Category:</h3>
            {project.acnu_features &&
              (Array.isArray(project.acnu_features) ? (
                project.acnu_features.length > 0 &&
                project.acnu_features[0].v1 &&
                project.acnu_features[0].v1.approval_category_main ? (
                  <p className="capitalize">
                    {project.acnu_features[0].v1.approval_category_main.toLowerCase()}
                  </p>
                ) : (
                  <p>N/A</p>
                )
              ) : Object.keys(project.acnu_features).length > 0 ? (
                <p className="capitalize">
                  {project.acnu_features.v1?.approval_category_main?.toLowerCase() ||
                    "N/A"}
                </p>
              ) : (
                <p>N/A</p>
              ))}
          </div>
          {/* Approved Category */}

          <div className="overview-item">
            <h3>Dosage Form:</h3>
            {project.acnu_features &&
              (Array.isArray(project.acnu_features) ? (
                project.acnu_features.length > 0 &&
                project.acnu_features[0].v1 &&
                project.acnu_features[0].v1.dosage_form ? (
                  <ul className="capitalize">
                    {project.acnu_features[0].v1.dosage_form
                      ?.split(", ")
                      .map((form, i) => (
                        <li key={i}>{form.toLowerCase()}</li>
                      ))}
                  </ul>
                ) : (
                  <p>N/A</p>
                )
              ) : Object.keys(project.acnu_features).length > 0 ? (
                <ul className="capitalize">
                  {project.acnu_features.v1.dosage_form
                    ?.split(", ")
                    .map((form, i) => (
                      <li key={i}>{form.toLowerCase()}</li>
                    ))}
                </ul>
              ) : (
                <p>N/A</p>
              ))
            }
          </div>
          {/* Dosage Form */}

          <div className="overview-item">
            <h3>Dosage Route:</h3>
            {project.acnu_features &&
              (Array.isArray(project.acnu_features) ? (
                project.acnu_features.length > 0 &&
                project.acnu_features[0].v1 &&
                project.acnu_features[0].v1.dosage_route ? (
                  <p className="capitalize">
                    {project.acnu_features[0].v1.dosage_route.toLowerCase()}
                  </p>
                ) : (
                  <p>N/A</p>
                )
              ) : Object.keys(project.acnu_features).length > 0 ? (
                <p className="capitalize">
                  {project.acnu_features.v1.dosage_route.toLowerCase()}
                </p>
              ) : (
                <p>N/A</p>
              ))}
          </div>
          {/* Dosage Route */}
        </div>

        <div className="info-container">
          <div className="overview-item">
            <h3>Condition:</h3>
            <span>{project?.condition_analysis_name || "N/A"}</span>
          </div>
          {/* Condition */}

          <div className="overview-item">
            <h3>ACNU Difficulty:</h3>
            <span>{project?.acnu_difficulty || "N/A"}</span>
          </div>
          {/* ACNU Difficulty */}

          <div className="overview-item">
            <h3>Approval Date:</h3>
            <span>{formatDate(project?.most_recent_approval)}</span>
          </div>
          {/* Approval Date */}
        </div>
      </div>

      <div className="">
        <Summary summary={brand.brand_summary} />
      </div>
    </div>
  );
}

export default MedicationOverview;
