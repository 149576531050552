// react
import { useParams, Link, useLocation } from "react-router-dom";
import Markdown from "react-markdown";

import image from './image1.webp'

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faChartPie, faChevronRight, faLink } from "@fortawesome/free-solid-svg-icons";

// global components
import Loading from "../../components/Loading/Loading";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import DynamicCrumbs from "../../components/Breadcrumbs/DynamicCrumbs";
import RefreshButton from "../../components/RefreshButton/RefreshButton";

// local components
import AnnualForecast from "./Visualizations/AnnualForecast/AnnualForecast";
import MedicationOverview from "./Visualizations/MedicationOverview/MedicationOverview";
import PrescriptionTrend from "./Visualizations/PrescriptionTrend/PrescriptionTrend";
import SearchVolMonthType from "./Visualizations/SearchVolMonthType/SearchVolMonthType";

// other components
import DirectCompetitorChart from "../CompetitiveAnalysis/DirectCompetitorChart";

// hooks
import useProject from "../../hooks/useProject";
import useBrandAnalysis from "../../hooks/useBrandAnalysis";

import "./Project.css";

export function isLongerThanDate(date) {
  // threshhold date is sept 1 2024
  const threshhold = new Date("2024-09-01");
  const lastRun = new Date(date);

  return threshhold - lastRun > 30 * 24 * 60 * 60 * 1000;
}

const Project = () => {
  const location = useLocation();
  const history = location.state?.history || [];

  const uuid = useParams().projectId;
  const { project, loading: loadingProject, error: errorProject } = useProject(uuid);
  const { brandData, loading: loadingBrand, error: errorBrand } = useBrandAnalysis(uuid);

  if(loadingProject || loadingBrand) {
    return <Loading />
  }

  if(errorProject || errorBrand) {
    return <div className="project-page page-base">
      <ErrorDisplay error={errorProject || errorBrand} />
    </div>
  }

  if(!project?.condition_analysis_name) {
    return <div className="flex flex-col justify-center items-center">
      <p className="px-4 py-8 font-bold grid place-content-center">This project cannot be displayed because it does not have an associated condition.</p>
      <Link className="btn w-full md:max-w-[500px]" to="/companies">Back to Companies</Link>
    </div>
  }

  if(isLongerThanDate(project.last_run)) {
    // only projects that have a condition analysis and have 
    return <div className="p-4 flex flex-col justify-center items-center space-y-8">
      <h1 className="text-2xl">This project must be refreshed prior to viewing</h1>
      <div className="flex flex-col gap-4 justify-center items-center">
        <div className="p-2 text-5xl rounded hover:border hover:border-[#0561B7]">
          <RefreshButton
            refresh_property="project"
            project={project}
            project_eligible_for_analysis={project.eligible_for_analysis}
          />
        </div>

        <Link to="/companies" className="btn w-full md:min-w-[500px]">Back to Companies</Link>
        <Link to="/project-status" className="btn w-full md:min-w-[500px]">View Project Status</Link>
      </div>
    </div>
  }

  return (
    <div className="project-page page-base">
      <section>
        <DynamicCrumbs current={project.name} />

        <div className="page-header">
          <div className="content">
            <div className="flex flex-col md:flex-row md:gap-4 md:items-center col-span-1">
              <div className="grid">
                <div className="flex gap-4 items-center">
                  <h1 className="grad">{ project.name }</h1>
                  <Link to={`/project-edit`} state={{ project }}>
                    <FontAwesomeIcon icon={faEdit} />
                    <span className="sr-only">Edit { project.name }</span>
                  </Link>

                  <RefreshButton
                    refresh_property="project"
                    project={project}
                    project_eligible_for_analysis={
                      project.eligible_for_analysis
                    }
                  />

                  {project.url && (
                  <a href={project.url} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon
                      icon={faLink}
                    />
                  </a>
                )}
                </div>

                { project.last_run && (
                  <div className="text-sm mb-2">
                    {/* show date and time */}
                    Last run: {new Date(project.last_run).toLocaleString()}
                  </div>
                )}
              </div>

              <div className="acnu-section">
                {uuid === "e8d32602-0897-44a4-a14a-5152c1d44fdc" ? (
                  <Link className="btn max-w-[206px]" to={`/project/${uuid}/acnu_dashboard`}>
                    ACNU Dashboard
                    <FontAwesomeIcon className="mr-2" icon={faChartPie} />
                  </Link>
                ) : (
                  <button disabled>
                    ACNU Dashboard
                    <FontAwesomeIcon className="mr-2" icon={faChartPie} />
                  </button>
                )}
              </div>
            </div>

            {/* <ACNUInfo project={project} loading={loadingProject} /> */}
          </div>
        </div>

        <div className="page-section">
          <h2 className="section-header flex justify-between items-center">
            Overview of {project.name}
          </h2>

          <MedicationOverview project={project} brand={brandData} loading={loadingBrand || loadingProject} />
        </div>
      </section>

      <section className="page-section">
        <h2 className="section-header">
          Overview of { project.condition_analysis_name }
        </h2>

        <div className="overview">
          <img className="h-full w-full col-span-1 row-span-1 aspect-square border border-[#0561B7] border-opacity-50" src={image} alt="" />

          <Markdown className="md:col-span-5 space-y-2">
            { project?.condition_analysis?.frontpage_summary || "Summary is not available." }
          </Markdown>

          <hr className="col-span-full border-[#0561B7] border-opacity-50 border-dotted" />

          <div className="col-span-full">
            <PrescriptionTrend data={project.condition_analysis.population_chart_input} />
          </div>
        </div>
      </section>

      <section className="page-section">
        <h2 className="section-header">
          <Link 
            className="flex justify-between items-center" 
            to={`/project/${uuid}/keyword-universe`}
            state={{
              from: `/project/${uuid}`,
              history: [
                ...history,
                {name: project.name, path: `/project/${uuid}`},
              ],
            }}
          >
            Keyword Universe
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </h2>

        <SearchVolMonthType data={ project?.keyword_universe } />
      </section>

      <section className="page-section">
        <h2 className="section-header">
          <Link 
            className="flex justify-between items-center" 
            to={`/project/${uuid}/competitive-analysis`}
            state={{
              from: `/project/${uuid}`,
              history: [
                ...history,
                {name: project.name, path: `/project/${uuid}`},
              ],
            }}
          >
            Competitive Analysis
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </h2>

        <DirectCompetitorChart data={brandData.competitors} />
      </section>


      <section className="page-section">
        <h2 className="section-header">
          <Link 
            className="flex justify-between items-center" 
            to={`/project/${uuid}/acnu-feasibility`}
            state={{
              from: `/project/${uuid}`,
              history: [
                ...history,
                {name: project.name, path: `/project/${uuid}`},
              ],
            }}
          >
            ACNU Forecast
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </h2>

        <AnnualForecast data={project?.keyword_universe} />
      </section>

      <section className="page-section">
        <h2 className="section-header">Clusters</h2>

        <div className="p-4 flex justify-center">
          <Link to={`/clusters/${uuid}`} state={{
            from: `/project/${uuid}`,
            history: [
              ...history,
              {name: project.name, path: `/project/${uuid}`},
            ],
          }} className="btn w-full max-w-[400px]">View Clusters</Link>
        </div>
      </section>
    </div>
  );
};

export default Project;