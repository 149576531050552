// react
import { useParams } from "react-router-dom";
import Markdown from "react-markdown";

// global components
import DynamicCrumbs from "../../components/Breadcrumbs/DynamicCrumbs";
import Loading from "../../components/Loading/Loading";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import Summary from "../../components/Summary/Summary";

// local components
import ConsumerSearchChart from "./ConsumerSearchChart";
import DirectCompetitorChart from "./DirectCompetitorChart";
import SWOTAnalysis from "./SWOTAnalysis";

// hooks
import useProject from "../../hooks/useProject";
import useBrandAnalysis from "../../hooks/useBrandAnalysis";

// styles
import "./CompetitiveAnalysis.css";

const CompetitiveAnalysis = () => {
  const uuid = useParams().projectId;
  const { project, loading: loadingProject, error: errorProject } = useProject(uuid);
  const { brandData, loading: loadingBrand, error: errorBrand } = useBrandAnalysis(uuid);

  if (loadingProject || loadingBrand) {
    return <Loading />;
  }

  if (errorProject || errorBrand) {
    return (
      <div className="competitive-analysis page-base space-y-4 md:space-y-8">
        <DynamicCrumbs current="Competitive Analysis" />
        <ErrorDisplay error={errorProject || errorBrand} />
      </div>
    );
  }

  if (!brandData || Object.keys(brandData).length === 0) {
    return <div className="competitive-analysis page-base space-y-4 md:space-y-8">
      <DynamicCrumbs current="Competitive Analysis" />
      <section className="page-section">
        <h1 className="mb-2">Competitive Landscape</h1>
        <p>No brand data available.</p>
      </section>
    </div>;
  }

  return (
    <div className="competitive-analysis page-base space-y-4 md:space-y-8">
      <section>
        <DynamicCrumbs current="Competitive Analysis" />
        <h1 className="mb-2">Competitive Landscape</h1>

        <div className="page-section">
          <h2 className="section-header">Overview</h2>
          <div id="competitive-landscape" className="p-4">
            <Summary summary={brandData.competitive_landscape} />
          </div>
        </div>
      </section>

      <section className="page-section">
        <h2 className="section-header">Direct Competitors</h2>
        
        {brandData.competitors && brandData.competitors.length > 0 ? (
          <DirectCompetitorChart data={brandData.competitors} />
        ) : (
          <p>No competitor data available at this time</p>
        )}
      </section>

      <section className="page-section">
        <h2 className="section-header">Market Demographic Analysis</h2>

        <div className="space-y-4 p-2 md:p-4">
          {brandData.demographic_analysis ? (
            <Markdown disallowedElements={['code', 'pre']}>{brandData.demographic_analysis}</Markdown>
          ) : (
            <p>No demographic analysis data available at this time</p>
          )}
        </div>
      </section>

      <section className="page-section">
        <h2 className="section-header">Consumer Search Activity</h2>
        
        <ConsumerSearchChart data={brandData.search_chart_data} />
      </section>

      <section className="page-section">
        <h2 className="section-header">S.W.O.T. Analysis</h2>

        { brandData.swot_headlines && brandData.swot_analysis &&
          <SWOTAnalysis
            swotHeadlines={brandData.swot_headlines}
            swotAnalysis={brandData.swot_analysis}
          />
        }
      </section>
    </div>
  );
};

export default CompetitiveAnalysis;
