import { useState } from "react";
import Markdown from 'react-markdown'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";

import spinner from "../../assets/img/spinner.svg";

import "./Summary.css";

function Summary({ summary }) {
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleFeedback = (e) => {
    console.log('Feedback submitted:', e.currentTarget.value);
    setLoading(true);

    setTimeout(() => {
      setFeedbackSubmitted(true);
      setLoading(false);

      setTimeout(() => {
        setFeedbackSubmitted(false);
      }, 3000);
    }, 1000);
  };

  return (
    <div className="summary">
      {summary ? (
        <Markdown disallowedElements={["code", "pre"]}>{summary}</Markdown>
      ) : (
        <p className="fallback">No summary available.</p>
      )}

      {feedbackSubmitted ? (
        <span className="feedback-thanks">Thank you for your feedback!</span>
      ) : (
        <div className="feedback-buttons">
          { loading 
            ? <img src={spinner} alt="loading" className="feedback-spinner" />
            : <>
              <button
                className="up"
                value="good"
                onClick={handleFeedback}
              >
                <FontAwesomeIcon icon={faThumbsUp} />
                <span className="sr-only">thumbs up</span>
              </button>

              <button
                className="down"
                value="bad"
                onClick={handleFeedback}
              >
                <FontAwesomeIcon icon={faThumbsDown} />
                <span className="sr-only">thumbs down</span>
              </button>
            </>
          }
        </div>
      )}
    </div>
  );
}

export default Summary;
