import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons"

// global components
import Loading from "../../components/Loading/Loading";

// local components
import ModelSelector from "./ModelSelector";
import OpenAiForm from "./OpenAiForm";
import VertexForm from "./VertexForm";

import "./GenAI.css";

// const GenAI = () => {
//   const [service, setService] = useState("openai");

//   const [response, setResponse] = useState('');
//   const [prompt, setPrompt] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [isDictating, setIsDictating] = useState(false);
//   const [speech, setSpeech] = useState(null);

//   useEffect(() => {
//     if ("webkitSpeechRecognition" in window) {
//       const recognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//       const speechRecognition = new recognition();

//       speechRecognition.onstart = () => {
//         console.log("Speech has been started");
//       };

//       speechRecognition.onresult = (event) => {
//         const text = event.results[0][0].transcript;
//         setPrompt(text);
//       };

//       speechRecognition.onend = () => {
//         console.log("Speech has been stopped");
//       };

//       speechRecognition.onerror = (event) => {
//         console.log("Something went wrong: " + event.error);
//       };

//       setSpeech(speechRecognition);
//     } else {
//       console.warn("Speech recognition not supported 😢");
//     }
//   }, []);

//   useEffect(() => {
//     if(isDictating && speech){
//       speech.start();
//     }else if(!isDictating && speech){
//       speech.stop();
//     }
//   }, [isDictating, speech]);

//   return (
//     <>
//       <div className="genai page-base">
//         <section className="page-content">
//           <div className="page-header">
//             <h1>GenAI</h1>
//           </div>
//           <ModelSelector
//             service={service}
//             setService={setService}
//             style={{ marginBottom: "2rem" }}
//           />

//           { service === "openai" ? (
//             <OpenAiForm 
//               prompt={prompt} 
//               setPrompt={setPrompt} 
//               setResponse={setResponse} 
//               setLoading={setLoading}
//               isDictating={isDictating}
//               setIsDictating={setIsDictating}
//               speech={speech}
//               setSpeech={setSpeech}
//             /> 
//           ) : (
//             <VertexForm
//               prompt={prompt} 
//               setPrompt={setPrompt} 
//               setResponse={setResponse} 
//               setLoading={setLoading}
//               isDictating={isDictating}
//               setIsDictating={setIsDictating}
//               speech={speech}
//               setSpeech={setSpeech}              
//             />
//           )
//           }
//         </section>

//         <section className="response-content">
//           <div className="response-header">
//             <h2>Response</h2>
//             <div className="response-feedback">
//               <button className="feedback-btn" aria-label="thumbs up">
//                 <FontAwesomeIcon icon={faThumbsUp} size="xs" />
//               </button>

//               <button className="feedback-btn" aria-label="thumbs down">
//                 <FontAwesomeIcon icon={faThumbsDown} size="xs" />
//               </button>
//             </div>
//           </div>
//           <div className="response">
//             <pre>
//               {response}
//             </pre>
//           </div>
//         </section>

//         { loading && <Loading />}
//       </div>
//     </>
//   );
// };

const GenAI = () => {
  return null
}

export default GenAI;
