import { useEffect, useState } from "react";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";

import { formatCurrency } from "../../../../utils/tableUtils.js";
import { isValidData, formatMonths } from "../../../../utils/chartUtils.js";
import { useTheme } from "../../../../utils/ThemeManager.jsx";

import "./Type.css";

const requiredProperties = [
  "Brand Subtotal.estimated_monthly_cost_trend",
  "Condition Subtotal.estimated_monthly_cost_trend",
  "Grand Total.search_volume_months",
];

// data: project.keyword_universe
function Type({ data }) {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (data == null || Object.keys(data).length === 0 || !isValidData(data, requiredProperties)) return;

    setOptions(chartOptions(data, theme));
  }, [theme]);

  if (data == null || Object.keys(data).length === 0 || !isValidData(data, requiredProperties)) {
    return <p className="min-h-[450px] px-4 py-8 font-bold grid place-content-center">Estimated spend by type data is not available.</p>
  };

  return (
    <div className="estimated-spend-type">
      <ChartDashboard config={options} id="estimated-spend-type-container" />
    </div>
  );
}

export default Type;

const chartOptions = (data, theme) => {
  const colors = {
    dark: ['#020021', '#FCFCFC'],
    light: ['#0561B7', '#C4D6EB'],
  }

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `estimated-spend-type-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `estimated-spend-type-cell`,
        chartOptions: {
          colors: colors[theme],
          chart: {
            type: "column",
            styledMode: false,
            height: 450,
          },
          title: {
            text: "Estimated Monthly Spend by Type",
          },
          xAxis: {
            categories: formatMonths(data['Grand Total'].search_volume_months),
          },
          yAxis: {
            min: 0,
            title: {
              text: "Spend in Millions",
            },
            stackLabels: {
              enabled: true,
              formatter: function () {
                // dollar sign and 2 decimal places
                return formatCurrency(this.total);
              },
              style: {
                color: theme === 'dark' ? '#3AE500' : '#020021',
                textOutline: "none",
                fontSize: "0.8rem",
              }
            },
          },
          tooltip: {
            headerFormat: "<b>{point.x}</b><br/>",
            formatter: function () {
              const colorDot = `<span style="color:${this.point.color};">●</span>`;
              return `<b>${this.x}</b><br/>${colorDot} ${this.series.name}: ${formatCurrency(this.y)}`;
            },
          },
          plotOptions: {
            column: {
              stacking: "normal",
            },
          },
          series: [
            {
              name: "Branded",
              data: data["Brand Subtotal"].estimated_monthly_cost_trend
            },
            {
              name: "Condition",
              data: data["Condition Subtotal"].estimated_monthly_cost_trend
            },
          ],
        },
      },
    ],
  };
};
