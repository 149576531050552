/**
 * Checks if the given `data` object contains all the required nested properties.
 * 
 * The function accepts an array of strings representing required properties, which are in dot notation
 * (e.g., "Brand Subtotal.estimated_monthly_cost_trend"). It splits these strings and recursively checks
 * if each nested property exists in the provided `data` object.
 * 
 * @param {Object} data - The object to validate against the required properties.
 * @param {string[]} requiredProperties - An array of required properties in dot notation.
 * @returns {boolean} - Returns `true` if all the required properties are found in the `data` object, otherwise `false`.
 * 
 * @example
 * // Example data object
 * const data = {
 *   "Brand Subtotal": {
 *     "estimated_monthly_cost_trend": 123
 *   },
 *   "Condition Subtotal": {
 *     "estimated_monthly_cost_trend": 456
 *   },
 *   "Grand Total": {
 *     "search_volume_months": [1, 2, 3]
 *   }
 * };
 * 
 * // Example usage of the function
 * const requiredProperties = [
 *   "Brand Subtotal.estimated_monthly_cost_trend",
 *   "Condition Subtotal.estimated_monthly_cost_trend",
 *   "Grand Total.search_volume_months"
 * ];
 * 
 * const isValid = isValidData(data, requiredProperties);
 * console.log(isValid); // Outputs: true
 * 
 * // If a required property is missing
 * const incompleteData = {
 *   "Brand Subtotal": {
 *     "estimated_monthly_cost_trend": 123
 *   },
 *   "Condition Subtotal": {
 *     "estimated_monthly_cost_trend": 456
 *   }
 * };
 * 
 * const isValidIncomplete = isValidData(incompleteData, requiredProperties);
 * console.log(isValidIncomplete); // Outputs: false
 */
export const isValidData = (data, requiredProperties) => {
  return requiredProperties.every(prop => {
    const keys = prop.split('.');
    let value = data;
    for (const key of keys) {
      if (!value[key]) {
        return false;
      }
      value = value[key];
    }
    return true;
  });
};

/* CHART STYLE FUNCTIONS
  these functions are used to set chart 
  colors, text colors based on the theme
========================================== */

// a function that returns a random hex id
// this might be useful to avoid id conflicts
const randomHexId = () => {
  return Math.floor(Math.random() * 16777215).toString(16);
};

function getTextColor(theme) {
  return theme === "dark" ? "#ffffff" : "#000000";
}

function getChartColors(theme) {
  // dont forget to update the css variables too!
  const colors = {
    dark: [
      "#9867FE",
      "#3A9397",
      "#FC9ABB",
      "#FAC093",
      "#FBDEA5",
      "#C7D8C5",
      "#DCFCBD",
      "#E7594B",
      "#F3B876",
    ],
    light: [
      "#FA3F00",
      "#FF7A00",
      "#B177FD",
      "#030000",
      "#FC794D",
      "#FFA24D",
      "#C8A0FE",
      "#666666",
      "#F3B876",
    ],
  };

  // Highcharts.setOptions({
  //   colors: colors[theme] || colors.dark,
  // });

  return colors[theme] || colors.dark;
}

function getGridLineColors(theme) {
  return theme === "dark" ? "#707073" : "#e6e6e6";
}

function getTheme(theme) {
  // Highcharts.theme = {
  //   chart: {
  //     backgroundColor: null,
  //   },
  //   title: {
  //     style: {
  //       color: getTextColor(theme),
  //     },
  //   },
  //   subtitle: {
  //     style: {
  //       color: getTextColor(theme),
  //     },
  //   },
  //   yAxis: {
  //     title: {
  //       style: {
  //         color: getTextColor(theme),
  //       },
  //     },
  //     labels: {
  //       style: {
  //         color: getTextColor(theme),
  //       },
  //     },
  //     gridLineColor: getGridLineColors(theme),
  //   },
  //   xAxis: {
  //     labels: {
  //       style: {
  //         color: getTextColor(theme),
  //       },
  //     },
  //     lineColor: getGridLineColors(theme),
  //     tickColor: getGridLineColors(theme),
  //   },
  //   legend: {
  //     itemStyle: {
  //       color: getTextColor(theme),
  //     },
  //   },
  // }
  // return Highcharts.theme;
}

// takes in an array of dates in the format "YYYY-MM"
// returns an array of formatted dates in the format "MMM YY"
function formatMonths(monthArray) {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  return monthArray.map(dateStr => {
    const [year, month] = dateStr.split("-");
    const monthIndex = parseInt(month, 10) - 1;
    const shortYear = year.slice(-2);
    return `${monthNames[monthIndex]} ${shortYear}`;
  });
}

function interpolateColor(color1, color2, factor) {
  const hexToRgb = hex => {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  };
  
  const rgbToHex = rgb => {
    return `#${rgb.map(x => x.toString(16).padStart(2, '0')).join('')}`;
  };

  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  const interpolated = rgb1.map((c1, i) => Math.round(c1 + factor * (rgb2[i] - c1)));
  return rgbToHex(interpolated);
}

function generateGradientColors(startColor, endColor, steps) {
  const colors = [];
  if(steps === 1) {
    return [startColor];
  }

  for (let i = 0; i < steps; i++) {
    colors.push(interpolateColor(startColor, endColor, i / (steps - 1)));
  }
  return colors;
}

const brandSort = {
  "Evaluation": 0,
  "Payment": 1,
  "Assistance": 2,
  "Usage": 3,
  "Side Effects": 4,
  "General Branded": 5,
}

const conditionSort = {
  "Preventative": 0,
  "Symptoms": 1,
  "Diagnosis": 2,
  "Treatment": 3,
  "Support": 4,
  "Clinical Trials": 5,
  "General Condition": 6,
}

export { brandSort, conditionSort, getTextColor, getChartColors, getGridLineColors, getTheme, randomHexId, formatMonths, generateGradientColors };