import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext.jsx";
import { useTheme } from "../../utils/ThemeManager.jsx";

import logoDark from "../../assets/img/codescripts-2.png";
import logoLight from "../../assets/img/codescripts-2-light.png";
import sunrisePink from "../../assets/img/sunrise_pink.svg";
import sunriseGrad from "../../assets/img/sunrise_grad.svg";
import ieLogoGrad from '../../assets/img/ie-logo-grad.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserTie,
  faArrowRightFromBracket,
  faCircleHalfStroke,
} from "@fortawesome/free-solid-svg-icons";

import { $axios } from "../../services/axiosInstance";
import { getAuth } from "firebase/auth";

import session from "../../stores/session";

import "./Header.css";

const Header = () => {
  const { logout } = UserAuth();
  const { clearUser } = session();
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;

  // Use the theme from ThemeManager
  const { theme, setTheme } = useTheme();

  const handleThemeToggle = () => {
    if (theme === "dark") {
      setTheme("light");
    } else {
      setTheme("dark");
    }
  };

  const handleLogout = () => {
    logout();
    delete $axios.defaults.headers.common["Authorization"];
    sessionStorage.removeItem('redirectPath');
    clearUser();
    navigate("/");
    setMenuOpen(false);
  };

  // Determine the correct logo based on theme
  const logo = theme === "dark" ? ieLogoGrad : ieLogoGrad;
  const mobileLogo = theme === "dark" ? ieLogoGrad : ieLogoGrad;

  return (
    <header className="app-header">
      <div className="app-header-content">
        <div className="app-header-left">
          <Link to="/companies" className="app-header-logo">
            <picture>
              <source media="(min-width: 768px)" srcSet={logo} />
              <img src={mobileLogo} alt="Codescripts" />
            </picture>
            <span className="sr-only">Welcome to Codescripts</span>
          </Link>
        </div>
        <div className="app-header-right">
          {user && user.email && (
            <div className="app-header-account-info">
              <button
                className="text-deep-space dark:text-white"
                onClick={handleThemeToggle}
                aria-label="theme toggle"
              >
                <FontAwesomeIcon icon={faCircleHalfStroke} />
              </button>

              <button
                onClick={() => setMenuOpen(!menuOpen)}
                aria-label="account menu"
              >
                {user.photoURL && (
                  <img
                    src={user.photoURL}
                    alt={user.displayName}
                    referrerPolicy="no-referrer"
                  />
                )}
              </button>

              <div
                className={`account-menu ${
                  menuOpen ? "block" : "hidden"
                }`}
                style={{ display: menuOpen ? "block" : "none" }}
              >
                <Link
                  className="app-header-link"
                  onClick={() => setMenuOpen(false)}
                  to="/admin"
                >
                  <FontAwesomeIcon icon={faUserTie} />
                  Admin
                </Link>
                
                <button
                  className="app-header-link"
                  onClick={handleLogout}
                  aria-label="sign out"
                >
                  <FontAwesomeIcon icon={faArrowRightFromBracket} />
                  Sign Out
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
