import { Link, useParams, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faMagnifyingGlass, faKey } from "@fortawesome/free-solid-svg-icons";
import { formatFloat } from '../../utils/tableUtils';

import Loading from "../../components/Loading/Loading";
import DynamicCrumbs from '../../components/Breadcrumbs/DynamicCrumbs';
import ErrorDisplay from '../../components/ErrorDisplay/ErrorDisplay';
import SearchVolBubble from "./Visualizations/SearchVolBubble"

import useClusters from './hooks/useClusters';

import './Clusters.css';

function Clusters() {
  const { clusterId } = useParams();
  const { clusterFeed, searchVol, loading, error } = useClusters(clusterId);

  const location = useLocation();
  const history = location.state?.history || [];

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="page-base space-y-4 md:space-y-8">
        <DynamicCrumbs current="Cluster Feed" />
        <ErrorDisplay error={error} />
      </div>
    );
  }

  if (clusterFeed.length === 0) {
    return (
      <div className='page-base cluster-feed space-y-4'>
        <DynamicCrumbs current="Cluster Feed" />
        <section className='page-section'>
          <h1 className='section-header'>Cluster Feed</h1>
          <div className='p-8 flex flex-col gap-4 justify-center items-center'>
            <h2 className='text-2xl text-semibold text-center'>No cluster feed available.</h2>
            <Link
              to={location.state?.from || '/companies'}
              className='btn w-full max-w-[400px]'
            >
              {location.state?.from ? 'Back' : 'Back to Companies'}
            </Link>
          </div>
        </section>
      </div>
    );
  }

  return (
    <div className='page-base cluster-feed space-y-4'>
      <section>
        <DynamicCrumbs current="Cluster Feed" />

      </section>

      <section className='page-section'>
        <h1 className='section-header'>Cluster Feed</h1>

        <SearchVolBubble data={searchVol} history={history} clusterId={clusterId} />

        <hr className="col-span-full border-[#0561B7] border-opacity-50 border-dotted mx-4" />

        <ul className='cluster-list'>
          {clusterFeed.map((item, index) => (
            <li key={index} className='page-section cluster-item'>
              <Link 
              title={item.title} 
              className='cluster-link' 
              to={`/cluster/${item.uuid}`}
              state={{
                from: `/clusters/${clusterId}`,
                history: [
                  ...history,
                  {name: "Cluster Feed", path: `/clusters/${clusterId}`},
                ],
              }}
              >
                <h2 className='section-header'>
                  <span>{item.title || 'No summary'}</span>
                </h2>

                <p className='p-4 pb-0'>
                  <span>{ item.summary || 'No summary'}</span>
                </p>

                <div title="Total Search Volume" className='flex justify-end gap-4 px-4 py-2 text-sm'>
                  <div className='flex gap-2 items-center'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                    <span>{formatFloat(item.total_search_volume, 0)}</span>
                  </div>
                  <div title="Total Keyword Count" className='flex gap-2 items-center'>
                    <FontAwesomeIcon icon={faKey} />
                    <span>{formatFloat(item.total_keyword_count, 0)}</span>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}

export default Clusters;