// react
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Markdown from "react-markdown";

// global components
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import RefreshButton from "../../components/RefreshButton/RefreshButton";

// local components
import KeywordsChart from "./Visualizations/KeywordsChart";

// styles
import "./ProjectKeywords.css";

const ProjectKeywords = () => {
  const location = useLocation();
  const { project, tkwl, kwl_summary } = location.state;

  const [searchQuery, setSearchQuery] = useState("");

  const handleChange = (value, projectId) => {
    setSearchQuery(value);
    // fetchFilteredData(searchQuery, projectId);
  };

  const handleSubmit = (e, projectId) => {
    e.preventDefault();
    // fetchFilteredData(searchQuery, projectId);
  };

  return (
    <div className="project-keywords page-base">
      <div className="space-y-2">
        <Breadcrumbs project={project} currentPage="Keyword Opportunities" />

        <h1 className="grad">{project.name}</h1>
      </div>

      <section className="page-section">
        <h2 className="section-header flex gap-2">
          Keyword Opportunities
          <RefreshButton refresh_property="keywordList" project={project} />
        </h2>

        <div className="keyword-search p-2">
          <form onSubmit={(e) => handleSubmit(e, project.uuid)}>
            <input
              type="text"
              placeholder="Search for a keyword"
              value={searchQuery}
              onChange={(e) => handleChange(e.target.value, project.uuid)}
            />

            <button>submit</button>
          </form>
        </div>

        <KeywordsChart data={tkwl} />
      </section>

      <section className="page-section">
        <h2 className="section-header">Insight Analysis</h2>

        <Markdown className="p-4 space-y-4">
          { kwl_summary || "No summary available" }
        </Markdown>
      </section>
    </div>
  );
};

export default ProjectKeywords;
