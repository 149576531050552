import { useState, useEffect } from "react";
import Highcharts from "highcharts";
import ChartDashboard from "../../../components/ChartDashboard/ChartDashboard.jsx";

import { useTheme } from "../../../utils/ThemeManager.jsx";
import { generateGradientColors } from "../../../utils/chartUtils.js";
// data: // data: cluster.visualization.serp_features
// Search Volume by Month by Type
const ClusterSERPFeatures = ({ data }) => {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (data == null ||data.length === 0) return;
    
    setOptions(chartOptions(data, theme));
  }, [theme]);

  if (data == null || data.length === 0) {
    return (
      <p className="h-[450px] px-4 py-8 font-bold text-center grid place-items-center">
        SERP Features data is not available.
      </p>
    );
  }

  return (
    <div className="max-w-[600px] mx-auto">
      <ChartDashboard config={options} async={true} id="cluster-serp-features" />
    </div>
  );
};

export default ClusterSERPFeatures;

const chartOptions = (data, theme) => {
  const colors = {
    dark: generateGradientColors("#FF3C6D", "#FF8F6E", data.length),
    light: generateGradientColors("#0561B7", "#C0D7ED", data.length),
  }
  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: "cluster-serp-features-cell",
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: "cluster-serp-features-cell",
        chartOptions: {
          colors: colors[theme],
          chart: {
            type: 'pie',
            styledMode: false,
            height: 450,
          },
          title: {
            text: 'Cluster SERP Features',
          },
          legend: {
            // horizontal left, vertical center
            layout: 'vertical',
            align: 'left',
            verticalAlign: 'middle',
          },
          tooltip: {
            useHTML: true,
            // pointFormat: '<b>{point.percentage:.1f}%</b>',
            formatter: function () {
              console.log(this)
              return `<div style="display: flex; flex-direction: column;">
                <div style="font-weight: bold; margin-bottom: 4px; font-size: 1.25rem">${this.key}</div>
                <div style="font-size: 1.5rem">${Highcharts.numberFormat(this.point.percentage, 0)}%</div>
              </div>`;
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false, // Enable data labels
                distance: -40,
                formatter: function () {
                  return `${Highcharts.numberFormat(this.percentage, 1)}%`; // Display the percentage with the point name
                },
                style: {
                  fontWeight: 'bold',
                  fontSize: '1.25rem',
                  color: 'contrast', // Ensure that the label contrasts with the background
                },
              },
              showInLegend: true,
            },
          },
          series: [
            {
              name: 'SERP Features',
              colorByPoint: true,
              data: data
            },
          ],
        }
      },
    ],
  };
};
